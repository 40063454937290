/*
 * Copyright 2022 Palantir Technologies, Inc. All rights reserved.
 */

$black: #111418 !default;

$dark-gray1: #1c2127 !default;
$dark-gray2: #252a31 !default;
$dark-gray3: #2f343c !default;
$dark-gray4: #383e47 !default;
$dark-gray5: #404854 !default;

$gray1: #5f6b7c !default;
$gray2: #738091 !default;
$gray3: #8f99a8 !default;
$gray4: #abb3bf !default;
$gray5: #c5cbd3 !default;

$light-gray1: #d3d8de !default;
$light-gray2: #dce0e5 !default;
$light-gray3: #e5e8eb !default;
$light-gray4: #edeff2 !default;
$light-gray5: #f6f7f9 !default;

$white: #fff !default;

$blue1: #184a90 !default;
$blue2: #215db0 !default;
$blue3: #2d72d2 !default;
$blue4: #4c90f0 !default;
$blue5: #8abbff !default;

$green1: #165a36 !default;
$green2: #1c6e42 !default;
$green3: #238551 !default;
$green4: #32a467 !default;
$green5: #72ca9b !default;

$orange1: #77450d !default;
$orange2: #935610 !default;
$orange3: #c87619 !default;
$orange4: #ec9a3c !default;
$orange5: #fbb360 !default;

$red1: #8e292c;
$red2: #ac2f33;
$red3: #cd4246;
$red4: #e76a6e;
$red5: #fa999c;

$vermilion1: #96290d !default;
$vermilion2: #b83211 !default;
$vermilion3: #d33d17 !default;
$vermilion4: #eb6847 !default;
$vermilion5: #ff9980 !default;

$rose1: #a82255 !default;
$rose2: #c22762 !default;
$rose3: #db2c6f !default;
$rose4: #f5498b !default;
$rose5: #ff66a1 !default;

$violet1: #5c255c !default;
$violet2: #7c327c !default;
$violet3: #9d3f9d !default;
$violet4: #bd6bbd !default;
$violet5: #d69fd6 !default;

$indigo1: #5642a6 !default;
$indigo2: #634dbf !default;
$indigo3: #7961db !default;
$indigo4: #9881f3 !default;
$indigo5: #bdadff !default;

$cerulean1: #1f4b99 !default;
$cerulean2: #2458b3 !default;
$cerulean3: #2965cc !default;
$cerulean4: #4580e6 !default;
$cerulean5: #669eff !default;

$turquoise1: #004d46 !default;
$turquoise2: #007067 !default;
$turquoise3: #00a396 !default;
$turquoise4: #13c9ba !default;
$turquoise5: #7ae1d8 !default;

$forest1: #1d7324 !default;
$forest2: #238c2c !default;
$forest3: #29a634 !default;
$forest4: #43bf4d !default;
$forest5: #62d96b !default;

$lime1: #43501b !default;
$lime2: #5a701a !default;
$lime3: #8eb125 !default;
$lime4: #b6d94c !default;
$lime5: #d4f17e !default;

$gold1: #5c4405 !default;
$gold2: #866103 !default;
$gold3: #d1980b !default;
$gold4: #f0b726 !default;
$gold5: #fbd065 !default;

$sepia1: #5e4123 !default;
$sepia2: #7a542e !default;
$sepia3: #946638 !default;
$sepia4: #af855a !default;
$sepia5: #d0b090 !default;
